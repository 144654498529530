<template lang="pug">
  main#main.organizations-list
    b-container(fluid)
      wc-breadcrumb(icon='fal fa-building')
      section.l-section
        .l-section-h
          b-row(cols='1' cols-lg='2')
            template(v-for='(item, index) in items')
              b-col.mb-3.pb-3.mb-md-4.pb-md-4
                organization-item(
                  :index='index'
                  :item.sync='item'
                  :rs='itemRelationships(item)')

          client-only
            infinite-loading(@infinite='infiniteHandler')
              div(slot='spinner')
                wc-loaders-infinite-spinner
              div(slot='no-more')
                wc-loaders-infinite-no-more
              div(slot='no-results')
                wc-loaders-infinite-no-results

          template(v-if='isLoading')
            wc-loaders-card-list
</template>

<script>
import WcBreadcrumb from '@components/shared/WcBreadcrumb'
import WcLoadersInfiniteNoMore from '@components/shared/loaders/infinite/WcLoadersInfiniteNoMore'
import WcLoadersInfiniteNoResults from '@components/shared/loaders/infinite/WcLoadersInfiniteNoResults'
import WcLoadersInfiniteSpinner from '@components/shared/loaders/infinite/WcLoadersInfiniteSpinner'
import OrganizationItem from '@components/layouts/shared/OrganizationItem'
import apiOrganizations from '@services/api/organizations'
import List from '@common/list'

export default {
  name: 'organizations-list',
  mixins: [List],
  components: {
    WcBreadcrumb,
    WcLoadersInfiniteNoMore,
    WcLoadersInfiniteNoResults,
    WcLoadersInfiniteSpinner,
    OrganizationItem,
  },
  props: {
    wns: {
      type: String,
      default: 'org',
    },
  },
  methods: {
    itemRelationships(item) {
      let rs = {}
      rs.identity = this.itemDataIncluded(
        this.$getDeep(item, 'relationships.identity.data.type'),
        this.$getDeep(item, 'relationships.identity.data.id')
      )
      return rs
    },
  },
  data() {
    return {
      apiBase: apiOrganizations,
    }
  },
}
</script>
